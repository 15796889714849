import React, { useEffect, useState, useRef } from "react";
import { cloneDeep } from "lodash";

/**
 * React Hook reminiscent of React Class setState method
 * With a few extra enhancements
 *
 * Like the useState hook, the first destructured value of this slice is the current state
 *
 * Second destructured item is a function for updating the state, but also takes a callback as a second
 * argument that is invoked after state is updated.
 *
 * Third function resets the state to its original value.
 *
 * @param {*} initialValue
 * @returns [current_state, setState_with_callback(), resetState()]
 */
const useSuperState = initialValue => {

    // Master state and setState functions
    const [state, setState] = useState(initialValue);

    // Create references to the callback, initial value and shallow copy of the state
    const initialRef = useRef();
    const callbacksRef = useRef([]);

    // This is necessary to prevent updated state from being used in resetSuperState.
    // Why? Who knows, it's one of life's great mysteries.
    useEffect(() => {
        initialRef.current = cloneDeep(initialValue);
    }, [initialValue]);

    // Listen for state updates and broadcast to our callbacks
    useEffect(() => {
        callbacksRef.current.forEach(callback => callback(state));
        callbacksRef.current = [];
    }, [state]);

    if (typeof(initialValue) !== 'object' || Array.isArray(initialValue) || !initialValue)
        return [state, setState];

    // Allow the state to be passed in piecemeal or entirety
    const setSuperState = (newState, callback) => {

        // If using a state function, pass a copy of state as a parameter for manipulation
        if (typeof(newState) === 'function')
            newState = newState(state);

        // Apply the new values to the existing state object
        Object.keys(newState).forEach(key => {
            state[key] = newState[key];
        });

        if (typeof(callback) === 'function')
            callbacksRef.current.push(callback);

        // Set the actual state in order to trigger the re-render
        return setState(prevState => ({ ...prevState, ...newState }));

    };

    const resetSuperState = callback => {

        if (typeof(callback) === 'function')
            callbacksRef.current.push(callback);

        setState(initialRef.current);
    };

    return [state, setSuperState, resetSuperState];

};

export default useSuperState;