import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Image } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

// Import custom styles
import "common/components/thumbnail/styles/styles.css";

const Thumbnail = props => {

    const { src, onClick } = props;

    return (
        <div className={'thumbnail'} onClick={onClick}>
            <Image src={src} fluid/>
        </div>
    );

};

export default Thumbnail;