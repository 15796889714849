import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Image } from "react-bootstrap";
import { Carousel } from "react-bootstrap";

// Import custom styles
import "common/components/carousel/styles/styles.css";

const CustomCarousel = forwardRef((props, ref) => {

    const [activeIndex, setActiveIndex] = useState(0);
    const items = props?.items || [];

    useImperativeHandle(ref, () => ({
        setActiveIndex
    }));

    return (
        <Carousel activeIndex={activeIndex} onSelect={setActiveIndex}>
            {items.map((item, key) => (
                <Carousel.Item key={key}>
                    <Image src={item} style={{ width: 'auto', maxWidth: '100%' }} fluid/>
                </Carousel.Item>
            ))}
        </Carousel>
    );
});

export default CustomCarousel;