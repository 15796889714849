import React, { forwardRef, useImperativeHandle, useRef } from "react";
import { useSuperState } from "helpers/hooks";
import { Modal, Image, Container, Row, Col, Badge } from "react-bootstrap";
import { Carousel, Thumbnail } from "common/components";
import TruncateMarkup from "react-truncate-markup";

import 'common/components/project_viewer/styles/styles.css';

const ProjectViewer = forwardRef((props, ref) => {

    const [state, setState, resetState] = useSuperState({
        show: false,
        project: {},
        resolve: null,
        reject: null,
        readMore: false
    });

    const carouselRef = useRef(null);

    const show = (project) => new Promise((resolve, reject) => {

        setState(prev => ({
            resolve,
            reject,
            project,
            show: true,
            readMore: false
        }));

    });

    useImperativeHandle(ref, () => ({
        show
    }));

    const { project, readMore } = state;
    const { title, gallery, description, tags, type } = project;

    return (<>
        <Modal
            show={state.show}
            onHide={() => setState({ show: false })}
            aria-labelledby="example-modal-sizes-title-sm"
            dialogClassName="modal-dialog-xxl project-viewer"
        >
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
                <Container fluid>
                    <Row>
                        <Col xl={9}>
                            {gallery && <>
                                <Carousel items={gallery} ref={carouselRef}/>
                            </>}
                        </Col>
                        <Col xl={3}>
                            {gallery && <>
                                <Container fluid className={'mb-3 d-none d-xl-block'}>
                                    <Row>
                                        {gallery.map((item, key) => (
                                            <Col xs={2} className={'p-0'}>
                                                <Thumbnail src={item} onClick={() => {
                                                    carouselRef.current.setActiveIndex(key);
                                                }}/>
                                            </Col>
                                        ))}
                                    </Row>
                                </Container>
                            </>}
                            <h2 className={'mb-1'}>
                                {title}
                            </h2>
                            <h6 className={'text-secondary'}>{type}</h6>
                            <h6 className={'mt-4'}>Description</h6>
                            <hr className={'my-2'}/>
                            <div className={'project-description'}>
                                <small>
                                    {!readMore &&
                                    <TruncateMarkup
                                        lines={15}
                                        ellipsis={<div><a href="#" onClick={e => { e.preventDefault(); setState({ readMore: true }) }}>read more</a></div>}
                                    >
                                        <div>
                                            {description}
                                        </div>
                                    </TruncateMarkup>
                                    }
                                    {readMore && description}
                                </small>
                            </div>
                            <h6 className={'mt-4'}>Tags</h6>
                            <hr className={'my-2'}/>
                            {tags && tags?.general && <>
                                <div><small><b>General</b></small></div>
                                {tags.general.map((tag, key) => (
                                    <Badge key={key} bg={'primary'} className={'me-2'}>{tag}</Badge>
                                ))}
                            </>}
                            <br/>
                            <br/>
                            {tags && tags?.technical && <>
                                <div><small><b>Technical</b></small></div>
                                {tags.technical.map((tag, key) => (
                                    <Badge key={key} bg={'secondary'} className={'me-2'}>{tag}</Badge>
                                ))}
                            </>}
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
        </Modal>
    </>);

});

export default ProjectViewer;