import { useEffect, useRef } from "react";
import { useSuperState } from "helpers/hooks";
import PropTypes from "prop-types";

export default function Typer(props) {

    const [state, setState] = useSuperState({
        typedPhrase: '',
        showCursor: true
    });

    const { phrase, initialDelayMs, characterDelayMs, postDelayMs, onFinish } = props;
    const { typedPhrase, showCursor } = state;

    const cursorInterval = useRef();
    const initTimeout = useRef();
    const phraseTimeout = useRef();

    useEffect(() => {

        // Wait for initial delay, then begin showing characters
        initTimeout.current = setTimeout(() => { typePhrase(phrase) }, initialDelayMs);

        // Store reference to underscore
        cursorInterval.current = setInterval(() => {
            setState(prevState => ({
                showCursor: !prevState.showCursor
            }));
        }, 500);

        // Once unmounted, clear the cursor interval & stop the phrase timeout
        return () => {
            if (initTimeout.current)
                clearTimeout(initTimeout.current);
            if (cursorInterval.current)
                clearInterval(cursorInterval.current);
            if (phraseTimeout.current)
                clearTimeout(phraseTimeout.current);
        }

    }, []);

    // Recursive function to slowly type in a phrase character by character
    const typePhrase = (phrase) => {

        // If the input phrase has remaining characters, print it and recurisvely loop
        if (!phrase?.length) {
            setTimeout(() => {
                typeof(onFinish) == 'function' && onFinish();
            }, postDelayMs);
            return;
        }

        // Get next character from input phrase
        let nextChar = phrase[0];

        // Add it to the current phrase
        setState(prevState => ({
            typedPhrase: prevState?.typedPhrase + nextChar
        }));

        // Set a timeout to recursively call this function
        phraseTimeout.current = setTimeout(() => {
            let nextPhrase = phrase.substring(1);
            typePhrase(nextPhrase)
        }, addVariance(characterDelayMs));

    };

    // Add 10% variance to create realistic typing effect
    const addVariance = number => {

        // Calculate the 10% variance
        let variance = number * 0.3;

        // Generate a random sign for positive or negative variance
        let sign = Math.random() < 0.5 ? -1 : 1;

        // Apply the variance to the number
        return number + (variance * sign);

    };

    return (
        <>
            {Array.from(typedPhrase).map((char, index) => (
                <span key={index} className={`char-${index}`}>{char}</span>
            ))}
            <span className={'cursor'} style={{ opacity: showCursor ? 1 : 0 }}>_</span>
        </>
    );
}


Typer.defaultProps = {
    phrase: 'Hello World',
    initialDelayMs: 2500,
    postDelayMs: 1500,
    characterDelayMs: 200,
    onFinish: Function()
};

Typer.propTypes = {
    phrase: PropTypes.string,
    initialDelayMs: PropTypes.number,
    postDelayMs: PropTypes.number,
    characterDelayMs: PropTypes.number,
    onFinish: PropTypes.func
};