export default [
    {
        company_name: 'Talogy',
        title: 'Senior Director, Software Development',
        type: 'Full-time',
        dates: 'Feb 2022 - Present',
        truncated: true,
        hidden: false,
        points: <ul>
            <li>Led the successful migration of Solvably and Digital Skills Lab applications into PSI's ecosystem,
                including AWS migration, code repository transfer from GitLab to GitHub, and transitioning from GitLab
                issue boards to PSI Jira.
            </li>
            <li>Directed a technology team of 6 members, overseeing software engineers, QA engineer, and UI/UX designer,
                throughout the migration process and beyond.
            </li>
            <li>Orchestrated the seamless integration of the acquired products into PSI's operations by implementing the
                requested processes and procedures.
            </li>
            <li>Led the software engineering efforts for the product, driving innovation, overseeing feature
                development, and ensuring a high standard of quality.
            </li>
            <li>Rebranded the application and expanded its learning module types, transforming it into a versatile
                talent development solution within the PSI ecosystem.
            </li>
            <li>Integrated the application with other PSI products, ensuring a cohesive user experience and enhancing
                collaboration across the platform.
            </li>
            <li>Implemented essential functionalities such as import/export of SCORM packages and Single Sign-On (SSO),
                streamlining content integration and improving user convenience.
            </li>
        </ul>
    },
    {
        company_name: 'MassiveU',
        title: 'Vice President, Software Engineering',
        type: 'Full-time',
        dates: 'Sep 2019 - Mar 2022',
        truncated: true,
        hidden: false,
        points: <ul>
            <li>Reporting directly to the CEO, responsible for all technology operations of the company.</li>
            <li>Responsibilities include staff management, development operations, infrastructure, software engineering
                & application architecture, product management & design and technical sales.
            </li>
            <li>Established an effective software engineering workflow and adopted industry leading practices.</li>
            <li>Designed and architected the technology stacks used for the company’s products of the future, Solvably
                and Digital Skills Lab.
            </li>
            <li>Helped lead MassiveU’s technology team and Solvably and Digital Skills Lab products through an
                acquisition by PSI Services Inc. (Lifelong Learner Holdings / Talogy). Responsible for due diligence,
                technical coordination, operational migration, asset migration, and continuity of both products
                throughout the acquisition.
            </li>
        </ul>
    },
    {
        company_name: 'MassiveU',
        title: 'Full Stack Engineer',
        type: 'Full-time',
        dates: 'Jun 2014 - Sep 2019',
        truncated: true,
        hidden: false,
        points: <ul>
            <li>Responsible for front-end, back-end, product design, and light interface design for a multi-tenant
                web-based application relied upon by the leaders in the educational content industry.
            </li>
            <li>Responsible for conceptualizing, designing, and taking various interface prototypes from infant phase to
                full implementation.
            </li>
            <li>Worked directly with the CTO, CIO and CEO to solve many technical and business issues during critical
                high-stress times.
            </li>
            <li>Built and patented a software solution for automatically grading lessons, quizzes and exams within
                Microsoft Office.
            </li>
            <li>Built an educational textbook application allowing the industry’s largest content publisher to use their
                existing content in quick-access digital form, fulfilling a significant contract for the company.
            </li>
        </ul>
    },
    {
        company_name: 'Software Engineer',
        title: 'PMD Products',
        type: 'Part-time',
        dates: '2010 - 2014',
        truncated: true,
        hidden: true,
        points: <ul>
            <li>Developed and maintained a suite of multi-channel administrative e-commerce tools to help automate
                business operations.
            </li>
            <li>Includes applications used to automate order processing, manage shipments, and inventory.</li>
            <li>Marketplaces included Amazon, eBay, Walmart, YaHoo! Stores and Sears.com.</li>
        </ul>
    },
    {
        company_name: 'Bausch + Lomb',
        title: 'Web Content Specialist',
        type: 'Full-time',
        dates: '2012 - 2014',
        truncated: true,
        hidden: true,
        points: <ul>
            <li>Responsible for content management of B+L’s international web presence.</li>
            <li>Worked with HTML5, CSS3 and JS within the Sitecore content management system to produce appealing
                content.
            </li>
            <li>Efficiently migrated thousands of web pages across different content management systems.</li>
        </ul>
    }
];