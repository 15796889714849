import React, { useState } from "react";
import { Container, Row, Col, Image, Table, Button } from "react-bootstrap";
import TruncateMarkup from "react-truncate-markup";
import POSITIONS from "common/data/positions";

export default function About() {

    const [positions, setPositions] = useState(POSITIONS);
    const [showAllPositions, setShowAllPositions] = useState(false);

    return (
        <>
            <div className="layer-about" id="about">
                <div className="quoted">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <h1>About me</h1>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={8}>

                                {/* Intro blurb */}
                                <p>
                                    Hi, I'm Collin Stowell. I'm a results-oriented technology leader with a strong passion for software engineering, DevOps, cloud infrastructure, and team management. Committed to building high-functioning software and delivering highly usable experiences. Experienced in leading and developing small to mid-size engineering teams. Skilled in making sound technical decisions under pressure in fast-paced environments.
                                </p>
                                {/* Experience */}
                                <h5>Experience</h5>
                                <div>
                                    {positions.map(({ company_name, title, type, dates, points, truncated, hidden }, key) => (
                                        <div key={key} className={(!showAllPositions && hidden) ? 'd-none' : ''}>
                                            <h6>{title}</h6>
                                            <p>
                                                {company_name} &middot; <small className="text-secondary">{type}</small> &middot; <small className="text-secondary">{dates}</small>
                                            </p>
                                            <div className="mt-3">
                                                <small>
                                                    {truncated &&
                                                    <TruncateMarkup lines={7} ellipsis={<div><a href="#" onClick={e => { e.preventDefault(); setPositions(prevState => { prevState[key]['truncated'] = false; return [...prevState]; }); }}>read more</a></div>}>
                                                        {points}
                                                    </TruncateMarkup>
                                                    }
                                                    {!truncated && points}
                                                </small>
                                            </div>
                                        </div>
                                    ))}
                                    {!showAllPositions && <div>
                                        <Button variant="secondary" onClick={() => setShowAllPositions(true)}>View all</Button>
                                    </div>}
                                </div>
                            </Col>
                            <Col lg={4}>

                                {/* Background */}
                                <div className="mb-4">
                                    <div className="text-center mb-5">
                                        <Image src={require("assets/images/layer2_portrait_icon.png")} width={125} fluid/>
                                    </div>
                                    <h5>Background</h5>
                                    <p><small>I was born and raised in Western New York. Naturally, as a Western New Yorker, I'm also a lifelong Buffalo Bills fan. I lived there for 24 years before moving to Naples in sunny Southwest Florida with my wife, Rachel, and our two Mini Australian Shepherds, Bailey and Hazel.</small></p>
                                </div>

                                <hr/>

                                {/* Education */}
                                <div className="mt-4">
                                    <h5>Education</h5>
                                    <Table borderless>
                                        <tbody>
                                            {/* RIT */}
                                            <tr>
                                                <td className="align-top">
                                                    <small style={{ whiteSpace: 'nowrap' }}>2012-2014</small>
                                                </td>
                                                <td>
                                                    <small>
                                                        <b>Rochester Institute of Technology</b>
                                                        <br/>
                                                        Master of Science (M.S.), Information Technology
                                                    </small>
                                                </td>
                                            </tr>
                                            {/* Binghamton */}
                                            <tr>
                                                <td className="align-top">
                                                    <small style={{ whiteSpace: 'nowrap' }}>2008-2012</small>
                                                </td>
                                                <td>
                                                    <small>
                                                        <b>Binghamton University</b>
                                                        <br/>
                                                        Bachelor of Science (B.S.), Computer Science
                                                    </small>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>

                            </Col>
                        </Row>
                    </Container>
                </div>

            </div>
        </>
    );
}