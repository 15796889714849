export default [
    {
        title: 'Front-end',
        key: 'frontend',
        skills: [
            {
                title: 'React',
                thumbnail: require('assets/images/icons/react.jpg')
            },
            {
                title: 'JavaScript',
                thumbnail: require('assets/images/icons/js.jpg')
            },
            {
                title: 'HTML & CSS',
                thumbnail: require('assets/images/icons/html.jpg')
            },
            {
                title: 'LESS & SASS',
                thumbnail: require('assets/images/icons/less.jpg')
            },
            {
                title: 'jQuery',
                thumbnail: require('assets/images/icons/jquery.jpg')
            },
            {
                title: 'Bootstrap',
                thumbnail: require('assets/images/icons/bootstrap.jpg')
            },
            {
                title: 'Angular.js',
                thumbnail: require('assets/images/icons/angular.jpg')
            },
            {
                title: 'Vue.js',
                thumbnail: require('assets/images/icons/vue.png')
            },
            {
                title: 'Figma',
                thumbnail: require('assets/images/icons/figma.jpg')
            },
            {
                title: 'Photoshop',
                thumbnail: require('assets/images/icons/photoshop.jpg')
            },
            {
                title: 'Adobe XD',
                thumbnail: require('assets/images/icons/adobexd.png')
            },
            {
                title: 'Internationalization',
                thumbnail: require('assets/images/icons/i18n.png')
            },
            {
                title: 'Accessibility',
                thumbnail: require('assets/images/icons/a11y.png')
            }
        ]
    },
    {
        title: 'Back-end',
        key: 'backend',
        skills: [
            {
                title: 'RESTful API',
                thumbnail: require('assets/images/icons/restfulapi.jpg'),
            },
            {
                title: 'Node.JS',
                thumbnail: require('assets/images/icons/nodejs.jpg')
            },
            {
                title: 'Express.js',
                thumbnail: require('assets/images/icons/express.jpg')
            },
            {
                title: 'PHP',
                thumbnail: require('assets/images/icons/php.jpg')
            },
            {
                title: 'MySQL',
                thumbnail: require('assets/images/icons/mysql.jpg')
            },
            {
                title: 'PostgreSQL',
                thumbnail: require('assets/images/icons/postgresql.jpg')
            },
            {
                title: 'Redis',
                thumbnail: require('assets/images/icons/redis.jpg')
            },
            {
                title: 'Socket.io',
                thumbnail: require('assets/images/icons/socketio.jpg')
            },
            {
                title: 'Sequelize ORM',
                thumbnail: require('assets/images/icons/sequelize.jpg')
            },
            {
                title: 'Passport.js',
                thumbnail: require('assets/images/icons/passport.jpg')
            },
            {
                title: 'Stripe',
                thumbnail: require('assets/images/icons/stripe.jpg')
            }
        ]
    },
    {
        title: 'Infrastructure & DevOps',
        key: 'infrastructure',
        skills: [
            {
                title: 'Git',
                thumbnail: require('assets/images/icons/git.jpg')
            },
            {
                title: 'Mercurial',
                thumbnail: require('assets/images/icons/mercurial.jpg')
            },
            {
                title: 'GitHub',
                thumbnail: require('assets/images/icons/github.png')
            },
            {
                title: 'GitLab',
                thumbnail: require('assets/images/icons/gitlab.jpg')
            },
            {
                title: 'Bitbucket',
                thumbnail: require('assets/images/icons/bitbucket.jpg')
            },
            {
                title: 'Jira',
                thumbnail: require('assets/images/icons/jira.jpg')
            },
            {
                title: 'Docker',
                thumbnail: require('assets/images/icons/docker.jpg')
            },
            {
                title: 'CI/CD',
                thumbnail: require('assets/images/icons/cicd.jpg')
            },
            {
                title: 'DNS',
                thumbnail: require('assets/images/icons/dns.jpg')
            },
            {
                title: 'AWS',
                thumbnail: require('assets/images/icons/aws.jpg')
            },
            {
                title: 'AWS IAM',
                thumbnail: require('assets/images/icons/aws-iam.jpg')
            },
            {
                title: 'AWS EC2',
                thumbnail: require('assets/images/icons/aws-ec2.jpg')
            },
            {
                title: 'AWS VPC',
                thumbnail: require('assets/images/icons/aws-vpc.jpg')
            },
            {
                title: 'AWS Lambda',
                thumbnail: require('assets/images/icons/aws-lambda.jpg')
            },
            {
                title: 'AWS Elastic Beanstalk',
                thumbnail: require('assets/images/icons/aws-elastic-beanstalk.jpg')
            },
            {
                title: 'AWS RDS',
                thumbnail: require('assets/images/icons/aws-rds-relational-database-service.jpg')
            },
            {
                title: 'AWS DynamoDb',
                thumbnail: require('assets/images/icons/aws-dynamodb.jpg')
            },
            {
                title: 'AWS ElastiCache',
                thumbnail: require('assets/images/icons/aws-elasticache.jpg')
            },
            {
                title: 'AWS S3',
                thumbnail: require('assets/images/icons/aws-s3.jpg')
            },
            {
                title: 'AWS CloudFormation',
                thumbnail: require('assets/images/icons/aws-cloudformation.jpg')
            },
            {
                title: 'AWS SES',
                thumbnail: require('assets/images/icons/aws-ses.jpg')
            },
            {
                title: 'AWS SNS',
                thumbnail: require('assets/images/icons/aws-sns.jpg')
            },
            {
                title: 'AWS Trusted Advisor',
                thumbnail: require('assets/images/icons/aws-trusted-advisor.jpg')
            },
            {
                title: 'AWS Config',
                thumbnail: require('assets/images/icons/aws-config.jpg')
            },
            {
                title: 'AWS CloudWatch',
                thumbnail: require('assets/images/icons/aws-cloudwatch.jpg')
            },
            {
                title: 'AWS CloudFront',
                thumbnail: require('assets/images/icons/aws-cloudfront.jpg')
            },
            {
                title: 'AWS Elastic Transcoder',
                thumbnail: require('assets/images/icons/aws-elastic-transcoder.jpg')
            },
            {
                title: 'AWS Shield',
                thumbnail: require('assets/images/icons/aws-shield.png')
            }
        ]
    },
    {
        title: 'Other',
        key: 'other',
        skills: [
            {
                title: 'iOS',
                thumbnail: require('assets/images/icons/xcode.jpg')
            },
            {
                title: 'Java',
                thumbnail: require('assets/images/icons/java.jpg')
            },
            {
                title: 'C++',
                thumbnail: require('assets/images/icons/cpp.jpg')
            },
            {
                title: 'Eclipse',
                thumbnail: require('assets/images/icons/eclipse.jpg')
            },
            {
                title: 'XML',
                thumbnail: require('assets/images/icons/xml.jpg')
            }
        ]
    }
];