export default [
    {
        title:'AIM',
        type: 'Talogy',
        description: <div>
            <p>
                In early 2022, the Solvably platform was acquired by PSI Services, Inc. PSI Services inc. moved the application into their new launched Talogy talent management brand. Shortly after being acquired, Solvably was rebranded and transformed into the AIM (Assessment, Insight and Mastery) platform, with the goal of offering a robust and comprehensive digital talent development solution.
            </p>
            <p>
                Over the course of the following year, the application transformed from a primarily K-12 oriented experience, to a full-blown talent management solution. Additional modules such as competency based courses, instructor-led live sessions, development journeys, 3rd party importable modules, and assessments were implemented into the platform to create a robust collaborative learning experience.
            </p>
            <p>
                The AIM solution enabled client administrators to perform a multitude of useful tasks to enhance their organizations development program. Those tasks included:
                <br/>
                <ul>
                    <li>
                        Ability to create and distribute custom content
                    </li>
                    <li>
                        Ability to view deep insights into each assigned module
                    </li>
                    <li>
                        Ability to view real-time metrics and analysis across their entire organization
                    </li>
                    <li>
                        Ability to interact with learners in real-time through the AIM facilitation modes - enabled for every module
                    </li>
                </ul>
            </p>
        </div>,
        tags: {
            general: ['talent management','scorm','collaborative','challenges','assessment','journey','instructor-led'],
            technical: ['development','devops','architecture','infrastructure','aws','react','express','socket','redis','rds','elastic beanstalk','bootstrap']
        },
        cover: require('assets/images/projects/aim/cover.jpg'),
        gallery: [
            require('assets/images/projects/aim/full/login1.jpg'),
            require('assets/images/projects/aim/full/learner-home.png'),
            require('assets/images/projects/aim/full/learner-home-start.png'),
            require('assets/images/projects/aim/full/challenge-getting-started.png'),
            require('assets/images/projects/aim/full/challenge-getting-started2.png'),
            require('assets/images/projects/aim/full/challenge-getting-started3.png'),
            require('assets/images/projects/aim/full/learner-profile.jpg'),
            require('assets/images/projects/aim/full/challenge-overview2.png'),
            require('assets/images/projects/aim/full/challenge-overview-video.png'),
            require('assets/images/projects/aim/full/course-intro.png'),
            require('assets/images/projects/aim/full/course-session.png'),
            require('assets/images/projects/aim/full/course-session2.png'),
            require('assets/images/projects/aim/full/author1.png'),
            require('assets/images/projects/aim/full/author2.png'),
            require('assets/images/projects/aim/full/author3.png'),
            require('assets/images/projects/aim/full/author4.png'),
            require('assets/images/projects/aim/full/author5.jpg'),
            require('assets/images/projects/aim/full/design-lab.png'),
            require('assets/images/projects/aim/full/instructor-content.png'),
            require('assets/images/projects/aim/full/instructor-home.png'),
            require('assets/images/projects/aim/full/library.png'),
            require('assets/images/projects/aim/full/login2.png'),
            require('assets/images/projects/aim/full/login-magic-link.png')
        ]
    },
    {
        title:'Solvably',
        type: 'MassiveU',
        description: <div>
            <p>
                In early 2019, MassiveU set out to create a collaborative problem solving application initially aimed at K-12 and higher education markets.
                After many strategy and product vision meetings, Solvably was born.
                Solvably is an academically sound learning framework for collaborative, creative problem solving, it incorporates elements of problem- and project based learning, design thinking, gamification, and inquiry based learning.
                It offers a library of 450+ ready-to-go, pedagogically sound learning challenges.
            </p>
            <p>
                Learners team up and solve real-world problems in a collaborative, real-time learning environment. Similar to Google Docs and other collaborative online experiences, team members on Solvably can "see" when other members are online, collaborate with them via text chat and various voice chat options, see where they're working and what they're working on. Teams complete a 14-step problem solving process and produce a real-world solution deliverable specifically for their challenge.
            </p>
            <p>
                Solvably brings together design thinking, collaboration, and real-world problem solving, enabling learners to develop the skills they need to thrive in the future. Unlike traditional edtech, the Solvably experience centers learning around solving an authentic challenge or problem. On top of being an incredible learning experience, Solvably is also an easy-to-author tool for instructors, professors, and employers. Creating, distributing and measuring content on Solvably is incredibly simple and effective.
            </p>
        </div>,
        tags:{
            general: ['edtech','collaborative','problem solving','k12','hied','chat','talent mgmt','prof development','startup','acquired'],
            technical: ['development','architecture','cloud','devops','node','react','express','socket','redis','rds','aws','elastic beanstalk','bootstrap']
        },
        cover: require('assets/images/projects/solvably/cover.jpg'),
        gallery: [
            require('assets/images/projects/solvably/full/home.jpg'),
            require('assets/images/projects/solvably/full/quickstart.jpg'),
            require('assets/images/projects/solvably/full/login.jpg'),
            require('assets/images/projects/solvably/full/challenge-overview.jpg'),
            require('assets/images/projects/solvably/full/challenge-hub.jpg'),
            require('assets/images/projects/solvably/full/challenge-step1.jpg'),
            require('assets/images/projects/solvably/full/challenge-step2.jpg'),
            require('assets/images/projects/solvably/full/gallery.jpg'),
            require('assets/images/projects/solvably/full/organization-dashboard.jpg'),
            require('assets/images/projects/solvably/full/profile1.jpg'),
            require('assets/images/projects/solvably/full/profile2.jpg'),
            require('assets/images/projects/solvably/full/talent-home.jpg'),
            require('assets/images/projects/solvably/full/talent-diversity.jpg'),
            require('assets/images/projects/solvably/full/challenge-compulsion1.jpg'),
            require('assets/images/projects/solvably/full/challenge-compulsion2.jpg'),
            require('assets/images/projects/solvably/full/challenge-completion.jpg')
        ]
    },
    {
        title:'Digital Skills Lab',
        type: 'MassiveU',
        description: <div>
                <p>
                    In early 2019, MassiveU embarked on a collaboration with a prominent content publisher to develop a cutting-edge digital solution aimed at enhancing learner readiness for the Microsoft Office Specialist (MOS) test. This collaborative effort resulted in the creation of a comprehensive training program specifically tailored to Microsoft Office proficiency.
                </p>
                <p>
                    Central to this training program is the Digital Skills Lab (dlab), a Microsoft Office add-in meticulously designed by MassiveU. Learners engage with a series of carefully crafted lessons within the dlab environment, immersing themselves in hands-on activities to enhance their Microsoft Office skills.
                </p>
                <p>
                    What sets the dlab apart is its ability to provide real-time feedback to learners through an auto-grading system. This proprietary and patented grading system allows learners to receive instant assessment and feedback as they progress through the lessons. This immediate feedback mechanism empowers learners to identify areas of improvement and adjust their approach accordingly.
                </p>
                <p>
                    The dlab add-in is seamlessly integrated into Microsoft Word, Microsoft Excel, and Microsoft PowerPoint, enabling learners to enhance their skills across these essential Office applications. Furthermore, this add-in is compatible with all Microsoft Office-enabled devices, ensuring a universal learning experience accessible to learners regardless of their preferred device. The dlab add-in can be easily added to the Microsoft Office suite through the AppSource store, streamlining the installation process for learners.
                </p>
                <p>
                    By leveraging the collaborative expertise of MassiveU and the content publisher, this digital solution for Microsoft Office training revolutionizes the learning experience, providing learners with the necessary tools to excel in MOS testing and enhance their proficiency in Microsoft Office applications.
                </p>
            </div>,
        tags: {
            general: ['development','auto grade','microsoft','add-in','app store','k12','training','word','excel','powerpoint','office online'],
            technical: ['development','aws','lambda','react','office sdk','express','node','bootstrap']
        },
        cover: require('assets/images/projects/dlab/cover.png'),
        gallery: [
            require('assets/images/projects/dlab/full/addin-word2.png'),
            require('assets/images/projects/dlab/full/ms-store.png'),
            require('assets/images/projects/dlab/full/store-cover.jpg'),
            require('assets/images/projects/dlab/full/addin-word1.png'),
            require('assets/images/projects/dlab/full/addin1.png'),
            require('assets/images/projects/dlab/full/addin2.png'),
            require('assets/images/projects/dlab/full/addin-excel1.png'),
            require('assets/images/projects/dlab/full/addin-excel2.png'),
            require('assets/images/projects/dlab/full/addin-powerpoint.png')
        ]
    },
    {
        title:'Project-based Social Learning (PBSL)',
        type: 'MassiveU',
        description: <div>
                <p>
                    In 2015, MassiveU unveiled its groundbreaking Project-based Learning Solution, affectionately known as PBSL. This innovative platform was specifically designed to serve as an ideal "publisher partner" software, enabling content publishers to seamlessly transform traditional learning curricula into immersive project-based learning experiences directly on the platform.
                </p>
                <p>
                    In addition to its project-focused capabilities, the PBSL platform also provided robust support for digital eText, empowering publishers to convert conventional textbook formats into engaging and interactive reading experiences accessible across various devices. As a result, PBSL quickly gained traction and found widespread adoption among numerous school districts nationwide.
                </p>
                <p>
                    PBSL's versatility shines through its seamless integration capabilities with any Learning Management System (LMS), ensuring smooth implementation within existing educational frameworks. The platform has received resounding praise from both educators and students alike for its elegant and user-friendly design.
                </p>
                <p>
                    PBSL encompasses a wealth of features that enhance the learning experience. Its social sharing functionality facilitates collaboration and knowledge exchange among students, fostering a sense of community and engagement. Gamification elements within the platform motivate learners by introducing game-like elements to drive participation and achievement. The platform's scaffolded learning approach provides learners with structured support and guidance as they progress through their projects. Moreover, PBSL aligns with educational standards, ensuring that 21st-century learning objectives are met.
                </p>
                <p>
                    With its harmonious blend of cutting-edge features and intuitive design, PBSL represents a transformative step forward in 21st-century education. Its impact has been felt across thousands of school districts nationwide, redefining the learning landscape and empowering students and teachers to embrace immersive project-based learning experiences like never before.
                </p>
            </div>,
        tags:{
            general: ['edtech','k12','hied','project based learning','pbl','etext','assessment','tiles','discussions','social'],
            technical: ['development','php','javascript','jquery','vue','rds','dynamodb','aws','ec2','aurora','async','bootstrap']
        },
        cover: require('assets/images/projects/pbsl/cover.jpg'),
        gallery: [
            require('assets/images/projects/pbsl/full/course-overview.jpg'),
            require('assets/images/projects/pbsl/full/intro.jpg'),
            require('assets/images/projects/pbsl/full/tile-activity.jpg'),
            require('assets/images/projects/pbsl/full/my-projects.jpg'),
            require('assets/images/projects/pbsl/full/community.jpg'),
            require('assets/images/projects/pbsl/full/awaiting-grading.png'),
            require('assets/images/projects/pbsl/full/class-dashboard.png'),
            require('assets/images/projects/pbsl/full/class-students.png'),
            require('assets/images/projects/pbsl/full/tile-activity-intro.png'),
            require('assets/images/projects/pbsl/full/tile-grid.jpg'),

        ]
    },
    {
        title:'SolveTogether',
        type: 'American Student Assistance',
        description: <div>
            <p>
                In early 2020, MassiveU and American Student Assistance (ASA) partnered to launch the SolveTogether learning contest targeted at middle and high school students in the Northeast region. This exciting initiative offered learners the opportunity to form teams and participate in one of four challenges for a chance to win prizes exceeding $10,000. The SolveTogether contest was built upon the proprietary Solvably platform, which was customized and branded with ASA's identity, including project-specific components. By combining the expertise of MassiveU and the resources of ASA, the SolveTogether contest provided a unique and engaging learning experience for students, fostering collaboration, problem-solving, and the chance to win incredible prizes.
            </p>
        </div>,
        tags: {
            general: ['solvably', 'contest', 'white label', 'northeast', 'middle school', 'collaborative', 'problem solving'],
            technical: ['development','devops','react','express','node','elastic beanstalk','aws','rds','s3','redis','socket','bootstrap']
        },
        cover: require('assets/images/projects/asa/cover.jpg'),
        gallery: [
            require('assets/images/projects/asa/full/landing-phase0.jpg'),
            require('assets/images/projects/asa/full/landing-phase1.jpg'),
            require('assets/images/projects/asa/full/landing-phase2.jpg'),
            require('assets/images/projects/asa/full/landing-phase3.jpg'),
            require('assets/images/projects/asa/full/landing-phase4.jpg'),
            require('assets/images/projects/asa/full/how-it-works.jpg'),
            require('assets/images/projects/asa/full/faq.jpg'),
            require('assets/images/projects/asa/full/challenge-overview.jpg'),
            require('assets/images/projects/asa/full/challenge1.jpg'),
            require('assets/images/projects/asa/full/dashboard1.jpg'),
            require('assets/images/projects/asa/full/dashboard2.jpg'),
            require('assets/images/projects/asa/full/dashboard3.jpg')
        ]
    },
    {
        title:'Arkansas Virtual Academy',
        type: 'K12.com',
        description: <div>
            <p>
                In the fall of 2020, MassiveU and K12.com joined forces in a partnership to pilot the Solvably program within K12.com's virtual academy. As part of this collaboration, learners were enrolled on the Solvably platform, which provided them with a unique learning experience. Additionally, an engaging Professional Development (PD) contest was organized exclusively for instructors within the K12.com Arkansas Virtual Academy (ARVA). The contest spanned a day and included live facilitation from Thom Markham, renowned as the "godfather" of project-based learning. This initiative aimed to enhance instructional practices and foster professional growth among the educators at ARVA.
            </p>
        </div>,
        tags: {
            general: ['solvably', 'contest', 'white label', 'k12.com', 'arkansas virtual academy', 'pd', 'collaborative', 'problem solving'],
            technical: ['development','devops','react','express','node','elastic beanstalk','aws','rds','s3','redis','socket','bootstrap']
        },
        cover: require('assets/images/projects/arva/cover.png'),
        gallery: [
            require('assets/images/projects/arva/full/phase0.jpg'),
            require('assets/images/projects/arva/full/phase0_mobile.jpg'),
            require('assets/images/projects/arva/full/phase1.jpg'),
            require('assets/images/projects/arva/full/phase2.jpg'),
            require('assets/images/projects/arva/full/phase3.jpg')
        ]
    },
    {
        title: 'World Series of Innovation',
        type: 'Network for Teaching Entrepreneurship (NFTE)',
        description: <div>
            <p>
                Following the successful completion of the MakeYourJob.com contest in 2015, NFTE (Network for Teaching Entrepreneurship) and MassiveU formed another partnership to host the World Series of Innovation in 2016. This prestigious contest provided learners with the opportunity to form teams and compete for significant cash prizes. Participants could choose from six different contests, each addressing a real-world problem. Each contest offered its own set of cash prizes and garnered sponsorship from esteemed companies such as Coca-Cola, Citi Foundation, MasterCard, Microsoft, GoDaddy, and SAP, who actively participated in the selection of winners. Due to its remarkable success, the World Series of Innovation contest was renewed for two subsequent years in 2017 and 2018.
            </p>
        </div>,
        tags: {
            general: ['edtech','entrepreneurship','k12','contest'],
            technical: ['development','php','javascript','jquery','photoshop','less','rds','dynamodb','aws','bootstrap']
        },
        cover: require('assets/images/projects/wsi/cover.jpg'),
        gallery: [
            require('assets/images/projects/wsi/full/home-support.jpg'),
            require('assets/images/projects/wsi/full/create-support.jpg'),
            require('assets/images/projects/wsi/full/intro.png'),
            require('assets/images/projects/wsi/full/register.jpg'),
            require('assets/images/projects/wsi/full/challenge-category-support.jpg'),
            require('assets/images/projects/wsi/full/admin-rubric.jpg'),
            require('assets/images/projects/wsi/full/tile.png'),
            require('assets/images/projects/wsi/full/tile-grid.png'),
            require('assets/images/projects/wsi/full/welcome.jpg')
        ]
    },
    {
        title:'MassiveU',
        type: 'MassiveU',
        description: <div>
            <p>
                The MassiveU.com corporate site was designed and developed in-house by the MassiveU SE team. It had exceptional responsiveness and captivating animations that enhance the user experience.
            </p>
        </div>,
        tags: {
            general: ['landing', 'corporate site', 'single page','animation', 'responsive'],
            technical: ['development','front-end','less','jquery','javascript','bootstrap']
        },
        cover: require('assets/images/projects/massiveu/thumbs/massiveu.jpg'),
        gallery: [
            require('assets/images/projects/massiveu/full/massiveu.jpg')
        ]
    },
    {
        title: 'Make Your Job 2.0',
        type: 'Network for Teaching Entrepreneurship (NFTE)',
        description: <div>
            <p>
                Inspired by the success of the World Series of Innovation and Make Your Job 1.0, Make Your Job 2.0 is a contest specifically designed for middle and high school learners. This engaging competition offers students a range of challenges to choose from, encouraging them to showcase their skills and creativity. By participating in MYJ, learners have the chance to win exciting cash prizes.
            </p>
            <p>
                In the MYJ 2.0 edition, the contest underwent significant enhancements, particularly in terms of the learner interface and home page design. A brand-new and distinctive learner interface was introduced, providing an intuitive and user-friendly platform for participants to navigate through the contest. The redesigned home page offered an immersive and captivating experience, further fueling the enthusiasm and engagement of the learners.
            </p>
            <p>
                MYJ serves as an inspiring platform that empowers students to showcase their talents and problem-solving abilities. By offering real cash prizes, the contest provides a tangible incentive for learners to explore their passions, develop their skills, and unleash their creative potential. MYJ's continuous evolution and the introduction of a refreshed learner interface in MYJ 2.0 further solidify its position as an exciting and impactful contest for middle and high school students.
            </p>
        </div>,
        tags: {
            general: ['entrepreneurship','k12','contest'],
            technical: ['development','design','php','javascript','jquery','photoshop','less','rds','dynamodb','aws','bootstrap']
        },
        cover: require('assets/images/projects/myj2/cover.jpg'),
        gallery: [
            require('assets/images/projects/myj2/full/home-1.jpg'),
            require('assets/images/projects/myj2/full/home-2.jpg'),
            require('assets/images/projects/myj2/full/home-3.jpg'),
            require('assets/images/projects/myj2/full/get-started.png'),
            require('assets/images/projects/myj2/full/activity.jpg')
        ]
    },
    {
        title: 'PugArena',
        type: 'Personal',
        description: <div>
            <p>
                PugArena, a personal project of mine, is dedicated to merging advanced gaming metrics with the popular game Counter-Strike: Global Offensive (CS:GO). The primary goal of PugArena is to provide users with exclusive access to these metrics, presented in a user-friendly format.
            </p>
            <p>
                Through PugArena's services, subscribers gain access to top-notch servers and automated match-making capabilities facilitated by our proprietary in-game addon/modification. This unique feature enhances the overall gaming experience by ensuring seamless and efficient matchmaking for players.
            </p>
            <p>
                Moreover, PugArena goes beyond traditional gaming platforms by offering subscribers valuable insights through advanced gaming metrics. These metrics, available exclusively through our service, provide users with valuable information and analysis about their gameplay performance, enabling them to track their progress and make informed decisions to improve their skills.
            </p>
            <p>
                The user-friendly presentation of these metrics ensures that players can easily comprehend and utilize the information provided. PugArena aims to empower gamers by offering them a comprehensive toolset to enhance their gaming experience and take their gameplay to the next level.
            </p>
            <p>
                Through its commitment to delivering high-quality services, exclusive features, and insightful metrics, PugArena sets itself apart as a valuable resource for CS:GO enthusiasts seeking to elevate their gaming performance and gain a competitive edge.
            </p>
        </div>,
        tags: {
            general: ['design', 'development', 'counter strike','pick up game','sourcemod','csgo','stats','insight','improvement','steam'],
            technical: ['design','development','php','angular.js','sourcemod','pawn','sourcepawn','csgo','socket','aws','rds','bootstrap']
        },
        cover: require('assets/images/projects/pugarena/cover.jpg'),
        gallery: [
            require('assets/images/projects/pugarena/full/pugarena_1.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_2.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_3.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_4.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_5.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_6.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_7.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_8.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_9.jpg'),
            require('assets/images/projects/pugarena/full/pugarena_10.jpg')
        ]
    },
    {
        title:'SensiDrive',
        type: 'Personal',
        description: <div>
            <p>
                Automobile accidents claim the lives of thousands of individuals annually. As automobiles become safer through automotive advancements, drivers still typically practice dangerous driving habits while operating motor vehicles. Identifying these dangerous habits happens often after tragedy has struck. Using a smartphone application, this project aims to deliver and evaluate a platform to readily identify poor driving habits. This platform identifies driving habits through the use of a smartphone and a server application. Driving data is collected using smartphone hardware including the GPS, gyroscope and accelerometer. The data collected from this hardware is then sent to and analyzed by a remote server application. This application will be responsible for determining driving habits as well as identifying road conditions from the gathered data. An evaluation of the server’s ability to convert the supplied data into a meaningful analysis is then used to determine the validity of using smartphones to analyze driving habits.
            </p>
        </div>,
        tags: {
            general: ['driving', 'driver safety', 'academic', 'rit', 'masters degree', 'capstone', 'graduate requirement', 'big data'],
            technical: ['design', 'development', 'native', 'web', 'php', 'objective-c', 'ios', 'rds', 'geospatial', 'accelerometer', 'gps', 'gyroscope']
        },
        cover: require('assets/images/projects/sensidrive/thumbs/sensidrive.jpg'),
        gallery: [
            require('assets/images/projects/sensidrive/full/sensidrive_1.png'),
            require('assets/images/projects/sensidrive/full/sensidrive_2.jpg'),
            require('assets/images/projects/sensidrive/full/sensidrive_3.png'),
            require('assets/images/projects/sensidrive/full/sensidrive_4.png')
        ]
    },
    {
        title:'PMD',
        type: 'PMD Products',
        description: <div>
            <p>
                PMD Products is a reputable family-owned ecommerce company specializing in the sale of tools and automobile parts. With a strong presence across multiple channels including Amazon, eBay, Sears.com, Walmart, and their self-owned storefront, PMD Products has established itself as a prominent player in the industry.
            </p>
            <p>
                As the company experienced significant growth, it encountered the common challenges associated with scaling an ecommerce business. These challenges encompassed critical areas such as inventory synchronization, customer support scalability, and efficient order lifecycle management across its various sales channels.
            </p>
            <p>
                To address these challenges, I took the initiative to develop a comprehensive set of tools and solutions. One of the key accomplishments was creating a robust inventory synchronization system that ensures accurate inventory levels across all channels. This system guarantees that no item ever goes out of stock, enhancing customer satisfaction and maximizing sales opportunities.
            </p>
            <p>
                Additionally, I assembled a range of tools aimed at streamlining the order lifecycle management process. These automated tools significantly reduce manual intervention and enable the vast majority of orders to be seamlessly fulfilled by third-party logistics providers (3PL) with minimal oversight. This automation not only optimizes operational efficiency but also enhances order accuracy and expedites the fulfillment process, resulting in improved customer experiences.
            </p>
            <p>
                Through the implementation of these innovative tools and solutions, PMD Products has overcome the typical growth-related hurdles faced by ecommerce businesses. The synchronized inventory system and automated order lifecycle management have contributed to improved operational efficiency, enhanced customer satisfaction, and increased sales revenue.
            </p>
        </div>,
        tags: {
            general: ['ecommerce', 'tools', 'multi channel', 'amazon', 'ebay', 'yahoo small business', 'php', 'inventory sync', 'order lifecycle mgmt'],
            technical: ['design', 'development', 'php', 'cron', 'ebay sdk', 'amazon seller sdk', 'rest api']
        },
        cover: require('assets/images/projects/pmd/thumbs/pmd.jpg'),
        gallery: [
            require('assets/images/projects/pmd/full/pmd.jpg'),
            require('assets/images/projects/pmd/full/ebay.png'),
            require('assets/images/projects/pmd/full/amazon.png'),
            require('assets/images/projects/pmd/full/pmdnew.png'),
            require('assets/images/projects/pmd/full/ginu_2.png')
        ]
    },
    {
        title:'FragHype',
        type: 'Personal',
        description: <div>
            <p>
                In 2006, I founded FragHype, a groundbreaking pick-up game service for Counter-Strike players, filling a void in the absence of organized match making. FragHype served as a versatile platform, providing timely news updates, immersive radio streaming, engaging tournaments, and in-depth statistical analysis for the Counter-Strike community.
            </p>
            <p>
                Alongside its comprehensive features, FragHype offered users the ability to track and analyze detailed stats and metrics, providing valuable insights into their gameplay performance. Players could easily access and review their individual and team statistics, enabling them to identify areas for improvement and track their progress over time.
            </p>
            <p>
                To ensure fair play, FragHype implemented a proprietary anti-cheat client, effectively detecting and banning over 1,000 users within three years. Its impact was evident through rapid user growth, reaching nearly 10,000 active users in the first year and tripling within three years.
            </p>
        </div>,
        tags: {
            general: ['gaming','counter strike','1.6','irc','pick up game','anti cheat','community','statistics'],
            technical: ['design','development','html','css','javascript','jquery','php','rds','metamod','sourcemod','pawn','sourcepawn']
        },
        cover: require('assets/images/projects/fraghype/thumbs/fraghype.jpg'),
        gallery: [
            require('assets/images/projects/fraghype/full/fh1.jpg'),
            require('assets/images/projects/fraghype/full/fh2.jpg')
        ]
    },
    {
        title:'Real estate concept',
        type: 'Personal',
        description: <div>
            <p>
                This was a concept design I put together for a friend who had recently opened his own real-estate business.
            </p>
        </div>,
        tags:{
            general: ['design','concept','real estate','photoshop','stickynav'],
            technical: ['design','photoshop','bootstrap']
        },
        cover: require('assets/images/projects/realestate/thumbs/realestate.jpg'),
        gallery: [
            require('assets/images/projects/realestate/full/realestate.jpg')
        ]
    },
    {
        title:'eReceptionist',
        type: 'Personal',
        description: <div>
            <p>
                This is a fully functional concept portal that was created as class work at Rochester Institute of Technology (RIT).
            </p>
        </div>,
        tags: {
            general: ['design', 'development', 'academic', 'rit'],
            technical: ['html', 'css', 'oracle', 'rds', 'php', 'javascript']
        },
        cover: require('assets/images/projects/ereceptionist/thumbs/ereceptionist.jpg'),
        gallery: [
            require('assets/images/projects/ereceptionist/full/ereceptionist_1.jpg'),
            require('assets/images/projects/ereceptionist/full/ereceptionist_2.jpg')
        ]
    }
];