import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import { Animate } from "react-simple-animate";
import { Typer, TypeOut } from "common/components";

export default function Intro() {

    const [isTyping, setIsTyping] = useState(true);

    useEffect(() => {

    }, []);

    return (
        <>
            <div className="layer-intro layer-cake" id="home">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <Container fluid>
                        {isTyping && <IntroTyper onComplete={() => setIsTyping(false)}/>}
                        {!isTyping && <IntroInformation/>}
                    </Container>
                </div>
            </div>
        </>
    );
}

const IntroTyper = props => {

    const [isAnimating, setIsAnimating] = useState(false);

    return (
        <>
            <Animate
                play={isAnimating}
                start={{ top: 0, position: 'relative' }}
                end={{ top: 'calc(-50vh - 150px)', position: 'relative' }}
                duration={1}
                easeType="cubic-bezier(0.34, 1.56, 0.64, 1)"
                onComplete={() => {
                    typeof(props?.onComplete) == 'function' && props.onComplete();
                }}
                >
                <h1 className="typer">
                    <Typer
                        phrase="init();" onFinish={() => {
                        setIsAnimating(true);
                    }}/>
                </h1>
            </Animate>
        </>
    );

};

const IntroInformation = props => {

    const [isAnimating, setIsAnimating] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setIsAnimating(true);
        }, 100);
    });

    const Delay = props => {

        const [show, setShow] = useState(false);
        const delay = parseInt(props?.ms) || 100;

        useEffect(() => {

            setTimeout(() => {
                setShow(true);
            }, delay);

        });

        return show ? props?.children : <></>;

    };

    return (
        <div>
            <Animate
                play={isAnimating}
                start={{ top: 'calc(-50vh - 150px)', position: 'relative' }}
                end={{ top: 0, position: 'relative' }}
                duration={2}
                easeType="cubic-bezier(0.34, 1.56, 0.64, 1)"

            >
                <div className="text-center">
                    <img src={require("assets/images/layer1_portrait_icon.jpg")} alt="Profile headshot" className="portrait img-responsive intro-slide intro-slide-portrait"/>
                </div>
            </Animate>
            <Animate
                play={isAnimating}
                start={{ left: 'calc(-50vw - 50%)', position: 'relative' }}
                end={{ left: 0, position: 'relative' }}
                duration={1}
                easeType="cubic-bezier(0.85, 0, 0.15, 1)"
            >
                <h1 className="text-center mb-2">
                    <span className="intro-slide intro-slide-name">
                        Hi, i'm Collin.
                    </span>
                </h1>
            </Animate>
            <Animate
                play={isAnimating}
                start={{ left: 'calc(50vw + 50%)', position: 'relative' }}
                end={{ left: 0, position: 'relative' }}
                duration={2.5}
                easeType="cubic-bezier(0.85, 0, 0.15, 1)"
            >
                <h2 className="text-center intro-slide intro-slide-occupation mb-5">and i'm a <TypeOut words={['devops manager.','technology leader.','software engineer.']} caret={true} pauseSpeed={5000} Node={'span'} /></h2>
            </Animate>
            <Row>
                <Col xl={6}>
                    <div className="d-flex mb-3">
                        <Animate
                            play={isAnimating}
                            start={{ left: 'calc(-50vw - 50%)', position: 'relative', width: '100%' }}
                            end={{ left: 0, position: 'relative', width: '100%' }}
                            duration={2.25}
                            easeType="cubic-bezier(0.85, 0, 0.15, 1)"
                        >
                            <Button
                                href="#about"
                                variant="secondary"
                                className="cta intro-slide intro-slide-learn"
                            >
                                learn about me
                            </Button>
                        </Animate>
                    </div>
                </Col>
                <Col xl={6}>
                    <div className="d-flex mb-3">
                        <Animate
                            play={isAnimating}
                            start={{ left: 'calc(50vw + 50%)', position: 'relative', width: '100%' }}
                            end={{ left: 0, position: 'relative', width: '100%' }}
                            duration={1.75}
                            easeType="cubic-bezier(0.85, 0, 0.15, 1)"
                        >
                            <Button
                                href="#projects"
                                className="cta cta-blue intro-slide intro-slide-portfolio"
                            >
                                view my projects
                            </Button>
                        </Animate>
                    </div>
                </Col>
            </Row>

        </div>
    );
};