import React from "react";
import { Image } from "react-bootstrap";

export default function Contact() {
    return (
        <>
            <div className="layer-contact" id="contact">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <h1>Contact me</h1>
                            <h3 className="text-indent shake">I pride myself on being accessible, please feel free to
                                contact me. Provided here are my e-mail address, LinkedIn and AngelList profiles.
                                Enjoy!</h3>
                        </div>
                        <div className="col-md-4 col-md-offset-1">
                            <div className="contact">
                                <Image src={require('assets/images/layer5_icon_gmail.jpg')}/>
                                <p>collinstowell [at] gmail.com</p>
                            </div>
                            <div className="contact">
                                <a
                                    href="https://www.linkedin.com/pub/collin-stowell/32/7a/465" target="_blank"
                                    rel="noreferrer">
                                    <Image src={require('assets/images/layer5_icon_linkedin.jpg')}/>
                                    <p>View LinkedIn Profile</p>
                                </a>
                            </div>
                            <div className="contact">
                                <a href="https://angel.co/collin-stowell" target="_blank" rel="noreferrer">
                                    <Image src={require('assets/images/layer5_icon_angellist.jpg')}/>
                                    <p>View Angel List Profile</p>
                                </a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}