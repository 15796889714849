import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { FaLinkedin, FaGithub, FaGitlab, FaAngellist } from "react-icons/fa";

function TopNav() {
    return (
        <Navbar expand="lg" className="bg-body-tertiary" sticky={"top"}>
            <Container>
                <Navbar.Brand href="#home"><b>{'<CollinStowell/>'}</b></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="#home">About</Nav.Link>
                        <Nav.Link href="#projects">Projects</Nav.Link>
                        <Nav.Link href="#skills">Skills</Nav.Link>
                        <Nav.Link href="#contact">Contact</Nav.Link>
                    </Nav>
                    <Nav className={'justify-content-end'}>
                        <Nav.Link href="https://www.linkedin.com/pub/collin-stowell/32/7a/465" target="_blank" rel="noreferrer" className={'me-2'}>
                            <FaLinkedin size={25}/>
                        </Nav.Link>
                        <Nav.Link href="https://gitlab.com/collinstowell/" target="_blank" rel="noreferrer" className={'me-2'}>
                            <FaGitlab size={25}/>
                        </Nav.Link>
                        <Nav.Link href="https://angel.co/collin-stowell" target="_blank" rel="noreferrer" className={'me-2'}>
                            <FaAngellist size={25}/>
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

export default TopNav;