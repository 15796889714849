import React, { useState } from "react";
import { Container, Row, Col, Image, Card, Tabs, Tab } from "react-bootstrap";
import SKILLS from "common/data/skills";

export default function Skills() {

    const [skillCategory, setSkillCategory] = useState('frontend');

    return (
        <>
            <div className="layer-skills" id="layer-skills">
                <Container>
                    <Row>
                        <Col md={12}>
                            <h1>Technical skills</h1>
                        </Col>
                    </Row>
                    <div>
                        <Tabs
                            id="skills-tabs"
                            activeKey={skillCategory}
                            onSelect={(k) => setSkillCategory(k)}
                            className="mb-3"
                        >
                            {SKILLS.map(({ title, key, skills }) => (
                                <Tab key={key} eventKey={key} title={title}>
                                    <h3 className={'mt-5'}>{title}</h3>
                                    <Row>
                                        {skills.map(({ title, thumbnail }, key) => (
                                            <Col key={key} lg={2} md={4} sm={4} xs={6} className={'text-center mb-5'}>
                                                <Card body>
                                                    <div style={{ height: 75, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        <div className={'d-inline-block'}>
                                                            <Image src={thumbnail} fluid style={{ maxHeight: 50, maxWidth: 50 }}/>
                                                        </div>
                                                    </div>
                                                    <div>{title}</div>
                                                </Card>

                                            </Col>
                                        ))}
                                    </Row>
                                </Tab>
                            ))}
                        </Tabs>
                    </div>
                </Container>
            </div>
        </>
    );
}