import React from "react";
import { TopNav, IntroLayer, AboutLayer, ProjectsLayer, SkillsLayer, ContactLayer } from "pages/home/components";

export default function Home() {
    return (
        <>

            <div className="home">

                {/* Intro layer */}
                <IntroLayer/>

                {/* Sticky topnav */}
                <TopNav/>

                {/* About layer */}
                <AboutLayer/>

                {/* Green divider */}
                <div className="layer-about-divider"></div>

                {/* Skills layer */}
                <SkillsLayer/>

                {/* Green divider */}
                <div className="layer-projects-divider"></div>

                {/* Projects layer */}
                <ProjectsLayer/>

                {/* Layer 4 Green Divider */}
                <div className="layer-skills-divider"></div>

                {/* Layer 5 */}
                <ContactLayer/>

                {/* Footer */}
                <footer>
                    <h3 className="text-center">Copyright &copy; {(new Date()).getFullYear()}. All rights reserved.</h3>
                </footer>

            </div>
        </>
    );
}