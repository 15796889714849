import React, { useRef } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ProjectViewer } from "common/components";

import PROJECTS from "common/data/projects";

export default function Projects() {
    const projectViewer = useRef();
    return (
        <>
            <div className="layer-projects" id="projects">
                <Container>
                    <Row>
                        <Col md={6}>
                            <h1>Projects</h1>
                            <h3 className="text-indent briefcase">Here you will find a few projects that I have been involved in throughout my career.</h3>
                        </Col>
                        <Col md={{ span: 5, offset: 1 }} lg={3}>
                            <Image src={require('assets/images/layer3_imac.png')} className="hidden-xs hidden-sm" fluid/>
                        </Col>
                    </Row>
                    <Row className={'pb-4'}>
                        {PROJECTS.map((project, key) => {
                            const { cover } = project;
                            return (
                                <Col key={key} md={2} xs={4} className={'mb-3'}>
                                    <a href={'#'} onClick={(e) => {
                                        e.preventDefault();
                                        projectViewer.current.show(project);
                                    }}>
                                        <Image src={cover} className={'p-0'} thumbnail fluid/>
                                    </a>
                                </Col>
                            );
                        })}
                    </Row>
                </Container>
            </div>
            <ProjectViewer ref={projectViewer}/>
        </>
    );
}